$( () => {
  window.addEventListener( 'message', event => {
    if( event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady' ) {
      $( '.bs-div--form-wrapper' ).addClass( 'show' );
    }

    try {
      const allowedOrigins = ['https://forms.hsforms.com', window.location.origin];
      if ( event.origin && allowedOrigins.includes( event.origin ) ) {
        if( event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady' ) {
          $( '.hs-input' ).focus( function(){
            $( this ).parent().parent().addClass( 'active' );
          } ).focusout( function(){
            if ( ! $( this ).val() ) {
              $( this ).parent().parent().removeClass( 'active' );
            } else {
              $( this ).parent().parent().addClass( 'active' );
            }
          } );

          const hsSubmit = document.querySelector( '.bs-div--form-wrapper .hs-submit' );
          const input = hsSubmit.querySelector( 'input[type="submit"]' );

          hsSubmit.addEventListener( 'click', ()=> {
            input.click();
          } );
        }
      } else {
        return false;
      }
    } catch ( e ) {
      console.error( e );
    }
  } );
} );
